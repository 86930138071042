<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row>
      <div>{{ $t('title.BasicInformation') }}</div>
      <el-divider />
    </el-row>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="处置总数量:">{{ form.totalQuantity }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预估处置金额">
            <p style="display:flex;margin: 0">
              <el-input v-model="form.forecastAmount" style="margin-right:20px" placeholder="请输入" />
              <el-select v-model="forecast" style="width:100%" clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option value="CNY" label="CNY">CNY</el-option>
              </el-select>
            </p>

          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="textarea" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title" style="display:inline-block;padding-right: 20px;">处置明细</span>
      <span>
        <el-button @click="allDeleteId()">批量删除</el-button>
        <el-button @click="exportClick()">导入模板下载</el-button>
        <el-upload
          ref="upload"
          action=""
          class="el-button padding-none"
          :http-request="uploadFile"
          :show-file-list="false"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="fileChange"
        >
          <el-button type="primary">导入处置明细</el-button>
        </el-upload>
        <!-- <el-button @click="exportClick()">导入处置明细</el-button> -->
      </span>
    </el-row>
    <el-row>
      <el-form ref="filterForm" :model="filterForm" label-width="120px" style="display: flex;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="SKU">
              <el-input v-model="filterForm.sku" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置仓库">
              <el-select v-model="filterForm.warehouseCode" filterable style="width:100%" clearable :placeholder="$t('page.selectPlaceholder')" @change="isFilter">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.warehouseCode"
                  :label="item.warehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style">
              <el-select v-model="filterForm.style" filterable style="width:100%" clearable :placeholder="$t('page.selectPlaceholder')" :loading="loading" @visible-change="_queryStyleList($event)">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :value="item.styleName"
                  :label="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="color">
              <el-select v-model="filterForm.color" filterable style="width:100%" clearable :placeholder="$t('page.selectPlaceholder')" :loading="loading" @visible-change="_queryColorList($event)">
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :value="item.colorName"
                  :label="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="size">
              <el-select v-model="filterForm.size" filterable style="width:100%" clearable :placeholder="$t('page.selectPlaceholder')" :loading="loading" @visible-change="_querySizeList($event)">
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :value="item.size"
                  :label="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-table ref="multipleTable" class="mb-3" border :data="applyTable" :header-cell-style="{background:'#fafafa'}" max-height="500px" @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55"
      />
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="PlatSKU"
        align="center"
      />
      <el-table-column
        prop="style"
        label="Style"
        align="center"
      />
      <el-table-column
        prop="color"
        label="Color"
        align="center"
      />
      <el-table-column
        prop="size"
        label="Size"
        align="center"
      />
      <!-- <el-table-column
        prop="style,color,size"
        :label="$t('page.Specifications')"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column> -->
      <el-table-column
        prop="warehouseCode"
        label="处置仓库"
        align="center"
      />
      <el-table-column
        prop="goodQuantity"
        label="良品库存数量"
        align="center"
      />
      <el-table-column
        prop="badQuantity"
        label="不良品库存数量"
        align="center"
      />
      <el-table-column
        prop="goodDisposeQuantity"
        label="良品处置数量"
        align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.goodDisposeQuantity"
            maxlength="300"
            :placeholder="$t('page.inputPlaceholder')"
            size="mini"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="badDisposeQuantity"
        label="不良品处置数量"
        align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.badDisposeQuantity"
            maxlength="300"
            :placeholder="$t('page.inputPlaceholder')"
            size="mini"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          />
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" style="margin-top:20px" class="row-bg" justify="center">
      <el-col :span="3">
        <go-back />
      </el-col>
      <el-col :span="3">
        <el-button type="primary" :loading="confirmLoading" @click="ConfirmApply()">提 交</el-button>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import GoBack from '@/components/GoBack'
import { disposeApplyImport, disposeApplyCommit, isWarehouseList } from '@/api/disposal-apply'
import { queryStyleList, querySizeList, queryColorList } from '@/api/listSelection'
import { Mixin } from '@/mixin/mixin.js'
import { deepClone } from '@/utils'
export default {
  components: {
    GoBack
  },
  mixins: [Mixin],
  data() {
    return {
      confirmLoading: false,
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      dataList: [],
      applyTable: [],
      ShippingInfoable: [],
      form: {
        forecastAmount: '',
        remark: '',
        totalQuantity: ''
      },
      filterForm: {},
      loading: false,
      forecast: 'CNY',
      isDataNo: '',
      fileList: [],
      warehouseList: [],
      multipleSelection: []
    }
  },

  mounted() {
    this._isWarehouseList()
  },
  methods: {
    isFilter(val) {
      const isTrueApply = this.applyTable.find(e => val === e.style || val === e.color || val === e.size || val === e.sku || val === e.warehouseCode)
      console.log(isTrueApply)
      this.applyTable.push(isTrueApply)
    },
    async _isWarehouseList() {
      const { datas } = await isWarehouseList()
      this.warehouseList = datas
    },
    handleDelete(index) { // 删除行数
      this.applyTable.splice(index, 1)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    allDeleteId() {
      this.multipleSelection.forEach(i => {
        this.applyTable.splice(this.applyTable.findIndex(j => j.id === i.id), 1)
      })
    },
    async _queryStyleList(flag) {
      if (flag) {
        try {
          this.loading = true
          const { datas } = await queryStyleList()
          this.styleOptions = datas
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },
    async _querySizeList(flag) {
      if (flag) {
        try {
          this.loading = true
          const { datas } = await querySizeList()
          this.sizeOptions = datas
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },
    async _queryColorList(flag) {
      if (flag) {
        try {
          this.loading = true
          const { datas } = await queryColorList()
          this.colorOptions = datas
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },
    // 处置申请提交
    ConfirmApply() {
      if (this.applyTable.length <= 0) {
        console.log(111)
        this.$message({
          type: 'error',
          message: '明细不能为空'
        })
        return false
      }
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false
        }
        const obt = Object.assign({}, this.form, { disposeDetailList: this.applyTable, dataNo: this.isDataNo })
        this._disposeApplyCommit(obt)
      })
    },
    async _disposeApplyCommit(data) {
      try {
        const { code, msg } = await disposeApplyCommit(data)
        if (code === 0) {
          this.visible = false
          this.$message({
            message: '恭喜你，这是一条成功消息',
            type: msg
          })
          this.applyTable = []
        } else {
          this.applyTable = []
        }
      } catch (err) {
        this.applyTable = []
        // this._pageTrayTask(this.、queryParams)
      }
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      if (name[name.length - 1] === 'xlsx' || name[name.length - 1] === 'xls') {
        this.$refs.upload.clearFiles()
        return true
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '只能上传 xlsx 或 xls的文件'
        })
        this.$refs.upload.clearFiles()
        // return false
      }
    },
    async uploadFile() {
      try {
        // const loadingInstance = Loading.service({ fullscreen: false, background: 'rgba(0, 0, 0, 0.7)' })
        const forms = new FormData()
        forms.append('file', this.file)
        const { code, msg, datas } = await disposeApplyImport(forms)
        this.dataList = deepClone(datas.list ? datas.list : [])
        this.applyTable = this.dataList
        this.form.totalQuantity = datas.totalQuantity ? datas.totalQuantity : ''
        this.isDataNo = datas.dataNo
        // loadingInstance.close()

        if (code === 0) {
          this.$message.success(msg)
          this.$refs.upload.clearFiles()
        }
        // 隐藏弹窗清空数据
        this.$refs.upload.clearFiles()
      } catch (err) {
        console.log(err)
      } finally {
        this.$refs.upload.clearFiles()
      }
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeAvatarUpload(file)) {
          this.uploadFile()
        }
      } else {
        this.importDialog = false
      }
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
